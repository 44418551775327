import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenStorageService} from '../../shared/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-customer-unknown",
  templateUrl: "./customer-unknown.component.html",
  styleUrls: ["./customer-unknown.component.scss"],
})
export class CustomerUnknownComponent implements OnInit {
  user:any;

  constructor(
    private tokenStorage: TokenStorageService,
      private router: Router,
      private translate: TranslateService,
    ) {}

  ngOnInit() {
    this.translate.use(this.tokenStorage.getLang()?this.tokenStorage.getLang().code:'en');
    this.user = this.tokenStorage.getUser();
  }

  signout() {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
