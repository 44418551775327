import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../../configs/app.config';
import { BYPASS_LOG } from 'src/app/shared/interceptor/token.interceptor';
import { TokenStorageService } from 'src/app/shared/auth/token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class TemplateStampaService {
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {}
  private apiUrl = 'https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/';

  getListCustomers(){
    return this.http.get<any[]>(`${API_ENDPOINT}api/v1/customersassociated/${this.tokenStorageService.getUser().serviceBureau.id}`, httpOptions);
  }
  getList(): Observable<any[]> {
    return this.http.get<any[]>(`${API_ENDPOINT}api/v1/printtemplate/${this.tokenStorageService.getCustomer().id}`, httpOptions);
  }

  getListForCustomerId(customerId:string): Observable<any[]> {
    return this.http.get<any[]>(`${API_ENDPOINT}api/v1/printtemplate/${customerId}`, httpOptions);
  }

  getItem(id: string): Observable<any[]> {
    return this.http.get<any[]>(`${API_ENDPOINT}api/v1/printtemplate/${this.tokenStorageService.getCustomer().id}/${id}`, httpOptions);
  }
  getVariabili(): Observable<any[]> {
    return this.http.get<any[]>(`${API_ENDPOINT}api/v1/variabile/${this.tokenStorageService.getCustomer().id}`, httpOptions);
  }
  convertZplToImage(template: string): Observable<Blob> {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.http.post(this.apiUrl, template, { headers, responseType: 'blob', context: new HttpContext().set(BYPASS_LOG, true) });
  }

  insert(obj: any): Observable<any> {
    return this.http.post(`${API_ENDPOINT}api/v1/printtemplate/${this.tokenStorageService.getCustomer().id}`, obj, httpOptions);
  }

  update(id: string, obj: any): Observable<any> {
    return this.http.put(`${API_ENDPOINT}api/v1/printtemplate/${this.tokenStorageService.getCustomer().id}/${id}`, obj, httpOptions);
  }

  // delete(id: string): Observable<any> {
  //   return this.http.delete(`${API_ENDPOINT}api/v1/materials/${id}`, httpOptions);
  // }

  regex = /({{[^{^}]*}})/gm;
  varSearch(str: any) {
    var m;
    var list = [];
    while ((m = this.regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === this.regex.lastIndex) {
        this.regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        if (groupIndex == 1 && match != undefined) {
          list.push(match);
        }
      });
    }
    return list;
  }
  getCustomer(){
    return this.tokenStorageService.getCustomer().id
  }
}
