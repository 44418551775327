// Change your API endpoint here
// export const API_ENDPOINT = 'http://localhost:3000/'
// export const WS_ENDPOINT = 'ws://localhost:3000/'

// Test
//export const API_ENDPOINT = 'https://smartrfid2.azurewebsites.net/codifybe/'
//export const WS_ENDPOINT = 'wss://smartrfid2.azurewebsites.net/codifybe/'

// Prod
export const API_ENDPOINT = 'https://web.codi-fy.com/codifybe/'
export const WS_ENDPOINT = 'wss://web.codi-fy.com/codifybe/'


