import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public tokenStorage: TokenStorageService, public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.auth.isAuthenticated()) {
      return true;
    } else if(this.tokenStorage.rememeber_me() && this.tokenStorage.getToken()){
      // Tentativo di refresh token
      return this.auth.refreshToken().pipe(
        map((token:any) => {
          this.tokenStorage.saveToken(token.accessToken);
          this.tokenStorage.saveUser(token);
          return true;
        }), // Accesso consentito dopo il refresh token
        catchError(() => {
          // Se il refresh token fallisce, reindirizza l'utente alla pagina di login
          this.router.navigate(['/']);
          return of(false);
        })
      );
    }
    else{
      this.router.navigate(['/']);
      return false;
    }

    // if (!this.auth.isAuthenticated()) {
    //   this.router.navigate(['/']);
    //   return false;
    // }
    // return true;
  }
}
