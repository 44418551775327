<div class="input-group pill-input-group">
    <span class="input-group-text"><i class="fa fa-search text-dark"> </i></span>
    <input class="form-control" type="text" placeholder="{{ 'FILTER_DATA' | translate }}" (keyup)="updateFilter($event)" />
  </div>
<div class="custom-datatable table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th *ngFor="let head of header" [class]="[head.ordable?null:'sort']" translate>{{ head.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows | slice : curPage * pageSize - pageSize : curPage * pageSize | paginate : { itemsPerPage: pageSize, currentPage: curPage, totalItems: rows.length }">
        <td *ngFor="let col of header" [innerHtml]="col.render(row)">
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th *ngFor="let head of header" [class]="[head.ordable?null:'sort']" translate>{{ head.label }}</th>
      </tr>
    </tfoot>
  </table>
  <nav class="float-end mt-3" aria-label="Page navigation">
    <pagination-controls class="my-pagination" (pageChange)="onPageChange((curPage = $event))" previousLabel="Precedente" nextLabel="Successivo"> </pagination-controls>
  </nav>
</div>
