<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
            <form class="theme-form" [formGroup]="formGroup">
              <div>
                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="login page" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="login page" />
                </a>
              </div>

              <h4>{{ 'SELECT_CUSTOMER' | translate }}</h4>
              <p>{{ 'SELECT_CUSTOMER_TO_CONTINUE' | translate }}</p>

              <div class="form-group">
                <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
              </div>



              <div class="form-group">
                <label class="col-form-label">{{ 'CUSTOMER' | translate }}</label>
                <ng-select formControlName="selectedCustomerId"
                    [items]="customers"
                    bindLabel="name"
                    bindValue="id"
                    (change)="onChange($event)"
                    [(ngModel)]="selectedCustomerId"
                >
                </ng-select>
                <div *ngIf="formGroup.controls.selectedCustomerId.touched && formGroup.controls.selectedCustomerId.errors?.required" class="text text-danger mt-1">{{ 'CUSTOMER_REQUIRED' | translate }}</div>
              </div>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="!formGroup.valid" (click)="selectCustomer()" type="button"><span>{{ 'SELECT_CUSTOMER' | translate }}</span></button>
                </div>
              </div>


              <div class="form-group mb-0">
                <div class="mt-3">
                  <div class="text-center">
                    <p style="margin-bottom: 15px;">{{ 'NO_CHOOSE' | translate }}</p>
                    <a class="btn btn-light d-block w-100 btn-lg" (click)="signout()"><span>{{ 'SIGN_OUT' | translate }}</span></a>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
