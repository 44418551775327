import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { TokenStorageService } from "../auth/token-storage.service";
import { TranslateService } from "@ngx-translate/core";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  user: any;

  constructor(private router: Router, private tokenStorage: TokenStorageService, private translate : TranslateService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnInit() {
    this.user = this.tokenStorage.getUser();
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  Orders = this.translate.instant('ORDERS');
  PrintedItems = this.translate.instant('PRINTED_ITEMS');
  Admin = this.translate.instant('ADMIN');
  Groups = this.translate.instant('GROUPS');
  Customers = this.translate.instant('CUSTOMERS');
  Materials = this.translate.instant('MATERIALS');
  Accounts = this.translate.instant('ACCOUNTS');
  Settings = this.translate.instant('SETTINGS');
  BarcodeAttributes = this.translate.instant('BARCODE_ATTRIBUTES');
  PrinterTemplates = this.translate.instant('PRINTER_TEMPLATES');
  Printers = this.translate.instant('PRINTERS');
  Multiorders = this.translate.instant('MULTIORDERS');


  dashboard: Menu = { path: "/dashboard", icon: "home", title: "Dashboard", type: "link", bookmark: true }
  orders: Menu = { path: "/orders", icon: "search", title: this.Orders, type: "link", bookmark: true }
  printedItems: Menu = { path: "/items", icon: "file", title: this.PrintedItems, type: "link", bookmark: true }
  multiorders: Menu = { path: "/multiorders", icon: "builders", title: this.Multiorders, type: "link", bookmark: true }

  admin: Menu = {
    title: this.Admin, icon: 'others', type: 'sub', active: false,
    children: [
      {
        path:'/admin/groups',
        title: this.Groups,
        type: 'link'
      },
      {
        path:'/admin/customers',
        title: this.Customers,
        type: 'link'
      },
      {
        path:'/admin/servicebureau',
        title:'Service Bureau',
        type: 'link'
      },
      {
        path:'/admin/materials',
        title: this.Materials,
        type: 'link'
      },
      {
        path:'/admin/accounts',
        title: this.Accounts,
        type: 'link'
      },

    ]
  }

  printers = {
    path:'/settings/printers',
    title: this.Printers,
    type: 'link'
  }

  settings: Menu = {
    title: this.Settings, icon: 'project', type: 'sub', active: false,
    children: [
      {
        path:'/settings/variabile',
        title: this.BarcodeAttributes,
        type: 'link'
      },
      {
        path:'/settings/templatestampa',
        title: this.PrinterTemplates,
        type: 'link'
      },

      // only admin, service_bureau
      (this.tokenStorage.getUser().roles[0]=='ROLE_ADMIN'||
      this.tokenStorage.getUser().roles[0]=='ROLE_SERVICE_BUREAU')?this.printers:{},
    ]
  }

  settingsBureau: Menu = { path: "/settings/printers", icon: "task", title: this.Printers, type: "link", bookmark: true }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "Menu",
    },
    // all users
    this.dashboard,
    this.orders,

    // only admin, group_admin, customer_admin
    (this.tokenStorage.getUser().roles[0]=='ROLE_ADMIN'||
    this.tokenStorage.getUser().roles[0]=='ROLE_GROUP_ADMIN'||
    (this.tokenStorage.getUser().roles[0]=='ROLE_SERVICE_BUREAU' && this.tokenStorage.getUser().serviceBureau.typeRight == 'FREE_REPRINT_AND_ORDERS')||
    this.tokenStorage.getUser().roles[0]=='ROLE_CUSTOMER_ADMIN')?this.printedItems:{},

    // only admin, group_admin, customer_admin
    (this.tokenStorage.getUser().roles[0]=='ROLE_ADMIN'||
    this.tokenStorage.getUser().roles[0]=='ROLE_SERVICE_BUREAU')?this.multiorders:{},
    

    // only admin, group_admin, customer_admin
    (this.tokenStorage.getUser().roles[0]=='ROLE_ADMIN'||
    this.tokenStorage.getUser().roles[0]=='ROLE_GROUP_ADMIN'||
    this.tokenStorage.getUser().roles[0]=='ROLE_CUSTOMER_ADMIN')?this.settings:{},


    // only admin
    this.tokenStorage.getUser().roles[0]=='ROLE_ADMIN'?this.admin:{},

    // only service_bureau
    this.tokenStorage.getUser().roles[0]=='ROLE_SERVICE_BUREAU'?this.settingsBureau:{},

  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
