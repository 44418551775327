<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
            <form class="theme-form" [formGroup]="prForm">
              <div>
                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="looginpage" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div>

              <h4>{{'FORGOT_PASSWORD' | translate}}</h4>
              <p>{{'FORGOT_PASSWORD_MESSAGE' | translate}}</p>

              <div class="form-group">
                <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
              </div>



              <div class="form-group">
                <label class="col-form-label">{{'EMAIL' | translate}}</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email" />
                <div *ngIf="prForm.controls.email.touched && prForm.controls.email.errors?.required" class="text text-danger mt-1">{{'EMAIL_REQIRED' | translate}}</div>
                <div *ngIf="prForm.controls.email.touched && prForm.controls.email.errors?.email" class="text text-danger mt-1">{{'EMAIL_INVALID' | translate}}</div>
              </div>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="!prForm.valid" (click)="passwordRecovery()" type="button"><span>{{'PASSWORD_RECOVERY_BTN' | translate}}</span></button>
                </div>
              </div>


              <div class="form-group mb-0">
                <div class="mt-3">
                  <div class="text-center">
                    <p style="margin-bottom: 15px;">{{'ALREADY_HAVE_ACCOUNT' | translate}}</p>
                    <a class="btn btn-light d-block w-100 btn-lg" [routerLink]="'/login'"><span>{{'BACK_TO_LOGIN' | translate}}</span></a>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
