import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../configs/app.config';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class SelectCustomerService {
    constructor(private http: HttpClient) {}

    getList(): Observable<any[]> {
        return this.http.get<any[]>(`${API_ENDPOINT}api/v1/customers`, httpOptions);
      }

      getGroupList(groupId: string): Observable<any[]> {
        return this.http.get<any[]>(`${API_ENDPOINT}api/v1/groupCustomers/${groupId}`, httpOptions);
      }

}

