import { Component, OnInit, Input } from '@angular/core';

export class HeaderItem{
    label: string;
    objectKey: string;
    style?: string = null;
    ordable: boolean = false;
    order? = (a,b,ord) => {
        if(ord == 'desc')
            return (a<b)?-1:(a>b)?1:1;
        else
            return (a>b)?-1:(a<b)?1:1;
    }
    render? = (row) => {
        return row[this.objectKey];
    }
    constructor(itemHead){
      this.label = itemHead.label;
      this.objectKey = itemHead.objectKey;
      this.ordable = itemHead.ordable || false;
      if(itemHead.order)
        this.order = itemHead.order;
      if(itemHead.render)
        this.render = itemHead.render;
    }
}

@Component({
  selector: 'app-data-table-cuba',
  templateUrl: './dataTableCuba.component.html',
  styleUrls: ['./dataTableCuba.component.scss']
})
export class DataTableCubaComponent implements OnInit {

  @Input() header: HeaderItem[];
  @Input() rows: any[];
  @Input() asincrono: boolean = false;
  @Input() curPage: number = 1;
  @Input() pageSize: number = 10;


  constructor() {
    
  }

  onPageChange($event){}

  ngOnInit() {
    this.header = this.header.map((item) => new HeaderItem(item));
    console.log(this.header)
   }
  updateFilter(filter){}
}


// <app-data-table-cuba [header]="header" [rows]="templateStampaList"></app-data-table-cuba>
// header: HeaderItem[] = [
//   {
//     label: "Code",
//     objectKey: "code",
//     ordable: true,
//     render: row => `<span class="badge badge-light-primary">${row.code}</span>`
//   },
//   {
//     label: "Name",
//     objectKey: "nome",
//     ordable: true,
//   },
//   {
//     label: "Label Format",
//     objectKey: "formato",
//     ordable: true,
//     render: row => `<span class="badge badge-light-info">${row.formato}</span>`
//   },
//   {
//     label: "Breaks",
//     objectKey: "breaks",
//     ordable: true,
//     render: row => `<span class="badge rounded-pill ${row.variabili_rottura===''?'badge-light f-light':'badge-primary'}">${row.variabili_rottura===''?0:row.variabili_rottura.split(',').length}</span>`
//   },
//   {
//     label: "",
//     objectKey: "action",
//     ordable: true,
//     render: row => `<div class="action">
//                       <a href="javascript:void" class="btn btn-sm btn-light" style="margin-right: 10px" (click)="edit(row)">
//                         <i class="fa fa-edit"></i>
//                       </a>
//                     </div>`
//   },
  

// ];