import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';
import { AuthenticationService } from '../services/authentication.service';
const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private tokenStorage: TokenStorageService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.context.get(BYPASS_LOG) === true)
            return next.handle(request);
        if (this.authenticationService.isAuthenticated()) {
            let token = this.tokenStorage.getToken();

            const headers = new HttpHeaders({
              'Authorization': `Bearer ${token}`,
              'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
              'Access-Control-Allow-Origin': '*'
            });

            request = request.clone( { headers } );
        }
        return next.handle(request);
    }
}
