import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  mySecondFunc() {
    console.log('test utils');
  }

  arrayDistinct(arrayItem: any[], col: string): any[] {
    var arr = [];
    for (let item of arrayItem) {
      if (arr.find((itemArr) => itemArr[col].toLowerCase() == item[col].toLowerCase()) == undefined) {
        arr.push(item);
      }
    }
    return arr;
  }
  arrayAssoc(arrayObj: any[], arrayString: string[], col: string, classe: Object): any[] {
    var retArr = [];
    for (let item of arrayString) {
      var x = arrayObj.find((itemArr) => itemArr[col].toLowerCase() == item.toLowerCase());
      if (x != undefined) retArr.push(x);
      else {
        var clone = this.clona(classe);
        clone[col] = item;
        retArr.push(clone);
      }
    }
    return retArr;
  }
  arrayDiff(startArray: any[], diffArray: any[], col: string) {
    var arr = [];
    for (let item of startArray) {
      if (diffArray.find((itemArr) => itemArr[col].toLowerCase() == item[col].toLowerCase()) == undefined) {
        arr.push(item);
      }
    }
    return arr;
  }
  clona(classe: Object) {
    return Object.assign(Object.create(Object.getPrototypeOf(classe)), classe);
  }
  clonaJson(json: Object) {
    return JSON.parse(JSON.stringify(json));
  }
}
export function decodeJwt(jwtToken: string): any | null {
  const [, payloadBase64] = jwtToken.split('.');
  if (!payloadBase64) {
    console.error('Invalid JWT format');
    return null;
  }

  try {
    const payloadJson = atob(payloadBase64);
    return JSON.parse(payloadJson);
  } catch (error) {
    console.error('Error decoding JWT payload:', error.message);
    return null;
  }

  
}
export function handleError(err: any): void {
  if (err.status === 403) {
    alert("Error 403: Unauthorized (Contact support)");
  } else if (err.status === 500) {
    alert("Error 500: CONTACT SUPPORT");
  } else {
    alert(err.error.message);
  }
}
