import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/shared/auth/token-storage.service';
import { Customer } from 'src/app/shared/models/Customer';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent {
  customer: Customer;
  constructor(public tokenStorage: TokenStorageService) {}

  ngOnInit() {
    this.customer = this.tokenStorage.getCustomer();
  }



}
