import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { decodeJwt } from '../services/utils.services';
import { JwtHelperService } from '@auth0/angular-jwt';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const CUSTOMER_KEY = 'customer';
const SERVICEBUREAU_KEY = 'servicebureau';
const LANG_KEY = 'lang';
const REMEMBER_ME = 'remember-me';

const jwtHelper = new JwtHelperService();
export function Lang() {
  // let lang;
  // if (localStorage.getItem(REMEMBER_ME) != null) lang = localStorage.getItem(LANG_KEY);
  // else lang = sessionStorage.getItem(LANG_KEY);

  let lang = localStorage.getItem(LANG_KEY);

  if (lang != null) {
    try {
      return JSON.parse(lang).code;
    } catch (err) {
      return 'en';
    }
  }
  return 'en';
}

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private user;
  constructor() {}

  signOut(): void {
    let lang = this.getLang();
    let orderView = localStorage.getItem('viewMode')
    if (this.rememeber_me()) localStorage.clear();
    else sessionStorage.clear();
    this.saveLang(lang);
    localStorage.setItem('viewMode', orderView);
  }
  public rememeber_me(remember_me: boolean = null) {
    let fromLocal = localStorage.getItem(REMEMBER_ME);
    if (remember_me == null) {
      remember_me = fromLocal == null ? false : fromLocal == 'true';
    }
    if (remember_me && fromLocal == null) localStorage.setItem(REMEMBER_ME, 'true');
    return remember_me;
  }
  public saveToken(token: string, remember_me: boolean = null): void {
    if (this.rememeber_me(remember_me)) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      sessionStorage.removeItem(TOKEN_KEY);
      sessionStorage.setItem(TOKEN_KEY, token);
    }
  }

  public saveUser(user: any): void {
    if (this.rememeber_me()) {
      localStorage.removeItem(USER_KEY);
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    } else {
      sessionStorage.removeItem(USER_KEY);
      sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  }

  public getToken(): string | null {
    if (this.rememeber_me()) {
      return localStorage.getItem(TOKEN_KEY);
    }
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getUser(): any {
    if (this.rememeber_me()){
      return JSON.parse(localStorage.getItem(USER_KEY));
    }
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
  public getUserObj(): User {
    return this.user;
  }

  public saveCustomer(c: any): void {
    if (this.rememeber_me()) {
      localStorage.removeItem(CUSTOMER_KEY);
      localStorage.setItem(CUSTOMER_KEY, JSON.stringify(c));
    } else {
      sessionStorage.removeItem(CUSTOMER_KEY);
      sessionStorage.setItem(CUSTOMER_KEY, JSON.stringify(c));
    }
  }

  public getCustomer(): any {
    if (this.rememeber_me()) {
      return JSON.parse(localStorage.getItem(CUSTOMER_KEY));
    }
    return JSON.parse(sessionStorage.getItem(CUSTOMER_KEY));
  }

  public saveServiceBureau(sb: any): void {
    if (this.rememeber_me()) {
      localStorage.removeItem(SERVICEBUREAU_KEY);
      localStorage.setItem(SERVICEBUREAU_KEY, JSON.stringify(sb));
    } else {
      sessionStorage.removeItem(SERVICEBUREAU_KEY);
      sessionStorage.setItem(SERVICEBUREAU_KEY, JSON.stringify(sb));
    }
  }

  public getSericeBureau(): any {
    if (this.rememeber_me()) {
      return JSON.parse(localStorage.getItem(SERVICEBUREAU_KEY));
    }
    return JSON.parse(sessionStorage.getItem(SERVICEBUREAU_KEY));
  }

  public saveLang(lang: any): void {
    localStorage.removeItem(LANG_KEY);
    localStorage.setItem(LANG_KEY, JSON.stringify(lang));
    sessionStorage.removeItem(LANG_KEY);
    sessionStorage.setItem(LANG_KEY, JSON.stringify(lang));
  }

  public getLang(): any {
    //if (this.rememeber_me()) {
      return JSON.parse(localStorage.getItem(LANG_KEY));
    // }
    // return JSON.parse(sessionStorage.getItem(LANG_KEY));
  }
}
