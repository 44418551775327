import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },

  {
    path: "logger",
    loadChildren: () => import("../../components/logger/logger.module").then((m) => m.LoggerModule),
  },

  {
    path: "items",
    loadChildren: () => import("../../components/items/items.module").then((m) => m.ItemsModule),
  },
  {
    path: "multiorders",
    loadChildren: () => import("../../components/multiorders/multiorders.module").then((m) => m.MultiordersModule),
  },
  {
    path: "orders",
    loadChildren: () => import("../../components/orders/orders.module").then((m) => m.OrdersModule),
  },

  {
    path: "admin",
    loadChildren: () => import("../../components/admin/config/config.module").then((m) => m.ConfigModule),
  },

  {
    path: "settings",
    loadChildren: () => import("../../components/settings/setting.module").then((m) => m.SettingModule),
  },

];
