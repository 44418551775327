import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-step-progress',
  templateUrl: './step-progress.component.html',
  styleUrls: ['./step-progress.component.scss']
})
export class StepProgressComponent implements OnInit {

  @Input() step: any[];
  @Input() status: string;
  @Input() selected: string;

  constructor() {}

  ngOnInit() { console.log(this.step, this.status, this.selected); }

}
