import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { PasswordRecoveryComponent } from "./auth/password-recovery/password-recovery.component";
import { SelectCustomerComponent} from "./auth/select-customer/select-customer.component";
import { Error404Component} from "./components/error/error404.component"
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { AuthGuardService } from "./shared/auth/auth-guard.service";
import { CustomerUnknownComponent } from "./auth/customer-unknown/customer-unknown.component";
import { SelectTemplateComponent } from "./auth/select-template/select-template.component";
import { SetPasswordComponent } from "./auth/set-password/set-password.component"

const routes: Routes = [

  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },

  {
    path: "login",
    component: LoginComponent,
    children: full

  },
  {
    path: "password-recovery",
    component: PasswordRecoveryComponent,
    children: full
  },
  {
    path: "set-password/:id",
    component: SetPasswordComponent,
    children: full
  },
  {
    path: "select-customer",
    component: SelectCustomerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "items/select-template",
    component: SelectTemplateComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "customer-unknown",
    component: CustomerUnknownComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: '',
    component: ContentComponent,
    children: content
  },
  {
    path: '**',
    pathMatch: 'full',
    component: Error404Component
}

];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
