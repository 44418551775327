<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm" (keyup.enter)="login()">
              <div>
                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="login page" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="login page" />
                </a>
              </div>

              <h4>{{'SIGNIN' | translate}}</h4>
              <p>{{'SIGNIN_MESSAGE' | translate}}</p>

              <div class="form-group">
                <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>

              </div>

              <div class="form-group">
                <label class="col-form-label">{{'EMAIL' | translate}}</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email" />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required" class="text text-danger mt-1">{{'EMAIL_REQUIRED' | translate}}</div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email" class="text text-danger mt-1">{{'EMAIL_INVALID' | translate}}</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">{{'PASSWORD' | translate}}</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><i class="fa fa-eye show"></i></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><i class="fa fa-eye-slash hide"></i></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required" class="text text-danger mt-1">{{'PASSWORD_REQUIRED' | translate}}</div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox" formControlName="remember_me" />
                  <label for="checkbox1">{{'REMEMBER_PASSWORD' | translate}}</label>
                </div>
                <a [routerLink]="'/password-recovery'" class="link">{{'FORGOT_PASSWORD' | translate}}</a>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="!loginForm.valid" (click)="login()" type="button"><span>{{'SIGNIN_BTN' | translate}}</span></button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
