import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { TokenStorageService } from '../../shared/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;
  public isLoggedIn = false;

  user: any;
  customer: any;

  constructor(private fb: FormBuilder, public router: Router, private authService: AuthenticationService, private tokenStorage: TokenStorageService, private translate: TranslateService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember_me: [false],
    });
  }

  ngOnInit() {
    this.translate.use(this.tokenStorage.getLang() ? this.tokenStorage.getLang().code : 'en');

    if (this.authService.isAuthenticated()) {
      this.isLoggedIn = true;
      this.user = this.tokenStorage.getUser();
      this.customer = this.tokenStorage.getCustomer();

      console.log('already logged...');

      if ((this.user.roles[0] === 'ROLE_ADMIN' || this.user.roles[0] === 'ROLE_GROUP_ADMIN') && !this.customer) this.router.navigate(['/select-customer']);
      else this.router.navigate(['/dashboard']);
    } else if (this.tokenStorage.rememeber_me() && this.tokenStorage.getToken()) {
      // Tentativo di refresh token
      this.authService.refreshToken().subscribe({
        next: (token: any) => {
          this.tokenStorage.saveToken(token.accessToken);
          this.tokenStorage.saveUser(token);
          this.isLoggedIn = true;
          this.user = this.tokenStorage.getUser();
          this.customer = this.tokenStorage.getCustomer();

          console.log('already logged...');

          if ((this.user.roles[0] === 'ROLE_ADMIN' || this.user.roles[0] === 'ROLE_GROUP_ADMIN') && !this.customer) this.router.navigate(['/select-customer']);
          else this.router.navigate(['/dashboard']);
        },
        error: () => {},
      });
    }
    else{
      this.tokenStorage.signOut();
    }
  }

  login() {
    const { email, password, remember_me } = this.loginForm.value;
    this.authService.login(email, password).subscribe(
      (data) => {
        this.tokenStorage.saveToken(data.accessToken, remember_me);
        this.tokenStorage.saveUser(data);

        if (data.customer) {
          this.tokenStorage.saveCustomer(data.customer);
        }

        if (data.serviceBureau) {
          this.tokenStorage.saveServiceBureau(data.serviceBureau);
        }

        this.isLoggedIn = true;
        this.user = this.tokenStorage.getUser();
        this.reloadPage();
      },
      (err) => {
        this.errorMessage = 'Invalid Email or Password!';
        //alert(JSON.stringify(err))
      }
    );
  }

  showPassword() {
    this.show = !this.show;
  }

  reloadPage(): void {
    window.location.reload();
  }
}
