import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TokenStorageService } from "src/app/shared/auth/token-storage.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  user: any;
  role: string;

  constructor(public router: Router, private tokenStorage: TokenStorageService) {

  }

  ngOnInit() {
    this.user = this.tokenStorage.getUser();
    if(this.user)
        this.role = this.user.roles[0];
  }

  logoutFunc() {

    this.tokenStorage.signOut();
    this.router.navigateByUrl('login');
    //window.location.reload();

  }

  getInitials(name:string) {

    const parole = name.split(' ');
    const inizialeNome = parole[0] ? parole[0].charAt(0) : '';
    const inizialeCognome = parole.length > 1 ? parole[1].charAt(0) : '';
    return inizialeNome + inizialeCognome;

  }
}
