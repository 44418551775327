<div class="media profile-media">
  <!--
  <img class="b-r-10" src="assets/images/dashboard/profile.png" alt="">
  -->
  <div class="avatar"> {{getInitials(user.name)}}</div>
  <div class="media-body"><span>{{user.name}}</span>
    <p class="mb-0 font-roboto">{{role}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="/app/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>{{'ACCOUNT'|translate}} </span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{'LOG_OUT'|translate}}</span></li>
</ul>
