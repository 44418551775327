<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
            <form class="theme-form" [formGroup]="prForm">
              <div>
                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="loginpage" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="loginpage" />
                </a>
              </div>

              <h4>{{'SET_PASSWORD' | translate}}</h4>
              <p>{{'SET_PASSWORD_TO_ENABLE_YOUR_ACCOUNT' | translate}}</p>

              <div class="form-group">
                <div class="alert alert-danger" *ngIf="isRegisterFailed">
                    Register failed: {{ message }}
                </div>
                <div class="alert alert-success" *ngIf="isRegistered">
                    {{ message }}<br/><a [routerLink] = "['/login']">Go to Login</a>
                </div>
              </div>






              <div class="form-group">
                <label class="col-form-label">{{'PASSWORD' | translate}}</label>
                <input
                    type="hidden"
                    class="form-control no-validation-icon no-success-validation"
                    formControlName="id" readonly
                >
                <input
                    type="password"
                    class="form-control no-validation-icon no-success-validation"
                    formControlName="password"
                >
                <div *ngIf="prForm.controls.password.touched && prForm.controls.password.errors?.required" class="text text-danger mt-1">{{'PASSWORDL_REQIRED' | translate}}</div>
              </div>

              <div class="form-group">
                <label class="col-form-label">{{'CONFIRM_PASSWORD' | translate}}</label>
                <input
                    type="password"
                    class="form-control no-validation-icon no-success-validation"
                    formControlName="confirmPassword"
                >
                <ng-container *ngIf="prForm.get('confirmPassword').hasError('confirm')">
                    <span class="invalid-feedback">Password not match</span>
                </ng-container>
            </div>
              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="!prForm.valid" (click)="register()" type="button"><span>{{'SET_PASSWORD' | translate}}</span></button>
                </div>
              </div>


              <div class="form-group mb-0">
                <div class="mt-3">
                  <div class="text-center">
                    <p style="margin-bottom: 15px;">{{'ALREADY_HAVE_ACCOUNT' | translate}}</p>
                    <a class="btn btn-light d-block w-100 btn-lg" [routerLink]="'/login'"><span>{{'BACK_TO_LOGIN' | translate}}</span></a>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
