import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusService {
  constructor(private translate: TranslateService) {}
  order_status = {
    STATUS_PENDING: {
      key: "1",
      label: 'Pending',
      cls: 'info'
    },
    STATUS_ASSIGNED: {
      key: "2",
      label: 'Assigned',
      cls: 'warning'
    },
    STATUS_ACCEPTED: {
      key: "3",
      label: 'Accepted',
      cls: 'warning'
    },
    STATUS_INPROCESS: {
      key: "4",
      label: 'InProcess',
      cls: 'warning'
    },
    STATUS_PRINTED: {
      key: "5",
      label: 'Printed',
      cls: 'success'
    },
    STATUS_DELIVERED: {
      key: "6",
      label: 'Delivered',
      cls: 'success'
    },
    STATUS_REJECTED: {
      key: "7",
      label: 'Rejected',
      cls: 'danger'
    },
    STATUS_ERROR: {
      key: "8",
      label: 'Error',
      cls: 'danger'
    },
  }

  getStatusKeyLabel(translate:boolean = true) {
    var ret = [];
    for (let item in this.order_status) {
      ret.push({
        key: this.order_status[item].key,
        label: translate?this.translate.instant('ORDER.' + this.order_status[item].label):'ORDER.' + this.order_status[item].label,
      });
    }
    return ret;
  }

  getKeyLabel(status: string){
    return {
      key: this.order_status[status].key,
      label: this.translate.instant('ORDER.' + this.order_status[status].label),
    }
  }

  getLabel(status: string) {
    return this.order_status[status].label
  }

  getStatusClass(status: string) {
    return this.order_status[status].cls;
  }

  getOrderStatus(key: number) {
    if (key == null)
      return this.order_status['STATUS_PENDING'];
    for (let item in this.order_status) {
      if (this.order_status[item].key === key + "")
        return item;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class PrinterStatusService {
  constructor(private translate: TranslateService) {}
  printer_status = {
    PRINTER_UNKNOWN: {
      key: "0",
      label: 'Printer.UNKNOWN',
      cls: 'info',
      ableprint: false
    },
    PRINTER_READY: {
      key: "1",
      label: 'Printer.READY',
      cls: 'success',
      ableprint: true
    },
    PRINTER_ERROR: {
      key: "2",
      label: 'Printer.ERROR',
      cls: 'danger',
      ableprint: false
    },
    PRINTER_WARN: {
      key: "3",
      label: 'Printer.WARN',
      cls: 'warning',
      ableprint: false
    },
    PRINTER_PRINTING: {
      key: "4",
      label: 'Printer.PRINTING',
      cls: 'warning',
      ableprint: false
    },
    PRINTER_JOB_STOPPED: {
      key: "5",
      label: 'Printer.JOB_STOPPED',
      cls: 'danger',
      ableprint: true
    },
    PRINTER_DISCONNECTED_REACHABLE: {
      key: "6",
      label: 'Printer.DISCONNECTED_REACHABLE',
      cls: 'success',
      ableprint: true
    },
    PRINTER_DISCONNECTED_UNREACHABLE: {
      key: "7",
      label: 'Printer.DISCONNECTED_UNREACHABLE',
      cls: 'danger',
      ableprint: false
    }
  }

  getLabel(status: string) {
    if(this.printer_status[status] == undefined)
      return this.printer_status.PRINTER_UNKNOWN.label;
    return this.printer_status[status].label
  }

  getPrinterStatus(key: number) {
    if (key == null)
      return this.printer_status['PRINTER_UNKNOWN'];
    for (let item in this.printer_status) {
      if (this.printer_status[item].key === key + "")
        return this.printer_status[item];
    }
  }

  getStatusKeyLabel() {
    var ret = [];
    for (let item in this.printer_status) {
      ret.push({
        key: this.printer_status[item].key,
        label: this.translate.instant(this.printer_status[item].label),
      });
    }
    return ret;
  }

  getStatusClass(status: string) {
    if(this.printer_status[status] == undefined)
      return this.printer_status.PRINTER_UNKNOWN.cls;
    return this.printer_status[status].cls;
  }
}

@Injectable({
  providedIn: 'root',
})
export class QueueStatusService {
  constructor(private translate: TranslateService) {}
  queue_status = {
    QUEUE_WAIT: {
      key: "1",
      label: 'QUEUE.WAIT',
      cls: 'info'
    },
    QUEUE_PAUSE: {
      key: "2",
      label: 'QUEUE.PAUSE',
      cls: 'info'
    },
    QUEUE_STOP: {
      key: "3",
      label: 'QUEUE.STOP',
      cls: 'info'
    },
    QUEUE_PRINTING: {
      key: "4",
      label: 'QUEUE.PRINTING',
      cls: 'warning'
    },
    QUEUE_ERROR: {
      key: "5",
      label: 'QUEUE.ERROR',
      cls: 'danger'
    },
    QUEUE_DONE: {
      key: "6",
      label: 'QUEUE.DONE',
      cls: 'success'
    }
  }

  getLabel(status: string) {
    return this.queue_status[status].label
  }

  getStatusClass(status: string) {
    return this.queue_status[status].cls;
  }
}
