<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
            <form class="theme-form" [formGroup]="formGroup">
              <!--
              <div>

                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="looginpage" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div>
              -->
              <h4>{{ 'SELECT_TEMPLATE' | translate }}</h4>
              <p>{{ 'SELECT_TEMPLATE_TO_CONTINUE' | translate }}</p>

              <div class="form-group">
                <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</div>
              </div>


              <div class="form-group" *ngIf="isServiceBureau && templates == null">
                <label class="col-form-label">{{ 'CUSTOMER' | translate }}</label>
                <ng-select formControlName="customerId"
                    [items]="customers"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="customerId"
                    (change)="detect($event)"                >
                </ng-select>
              </div>
              <div class="form-group" *ngIf="templates != null">
                <label class="col-form-label">{{ 'TEMPLATE' | translate }}</label>
                <ng-select formControlName="selectedTemplateId"
                    [items]="templates"
                    bindLabel="nome"
                    bindValue="id"
                    (change)="onChange($event)"                >
                </ng-select>
                <div *ngIf="formGroup.controls.selectedTemplateId.touched && formGroup.controls.selectedTemplateId.errors?.required" class="text text-danger mt-1">{{ 'TEMPLATE_REQUIRED' | translate }}</div>
              </div>
              <div class="form-group mb-0" *ngIf="templates != null">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="!formGroup.valid" (click)="setTemplate()" type="button"><span>{{ 'SELECT_TEMPLATE' | translate }}</span></button>
                </div>
              </div>
              <div class="form-group mb-0" *ngIf="isServiceBureau && templates == null">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100 btn-lg" [disabled]="customerId==null" (click)="next()" type="button"><span>{{ 'PROCEED' | translate }}</span></button>
                </div>
              </div>


              <div class="form-group mb-0">
                <div class="mt-3">
                  <div class="text-center">
                    <p style="margin-bottom: 15px;">{{ 'NO_CHOOSE' | translate }}</p>
                    <a class="btn btn-light d-block w-100 btn-lg" (click)="back()"><span>{{ 'DASHBOARD' | translate }}</span></a>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
