<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>

          <div class="login-main">
              <div>
                <a class="logo" routerLink="/">
                  <img class="img-fluid for-light" style="max-width: 260px;" src="assets/images/logo/logo.png" alt="looginpage" />
                  <img class="img-fluid for-dark" style="max-width: 260px;" src="assets/images/logo/logo_dark.png" alt="looginpage" />
                </a>
              </div>

              <h4>{{'USER' | translate}} {{user.name}} {{'NO_CUSTOMER' | translate}}</h4>
              <p>{{'CONTACT_ADMIN' | translate}}</p>

              <div class="form-group mb-0">
                <div class="mt-3">
                  <div class="text-center">
                    <a class="btn btn-light d-block w-100 btn-lg" (click)="signout()"><span>{{'SIGNOUT' | translate}}</span></a>
                  </div>

                </div>
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
