import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, mapTo, of, switchMap, tap, throwError } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import { API_ENDPOINT } from '../../configs/app.config';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {}

  login(email: string, password: string): Observable<any> {
    return this.http.post(
      `${API_ENDPOINT}api/auth/signin`,
      {
        email,
        password,
      },
      httpOptions
    );
  }

  setPassword(id: string, password: string): Observable<any> {
    return this.http.post(
      `${API_ENDPOINT}api/auth/setPassword`,
      {
        id,
        password,
      },
      httpOptions
    );
  }

  recoverPassword(email: string): Observable<any> {
    return this.http.post(
      `${API_ENDPOINT}api/auth/passwordRecovery`,
      {
        email,
      },
      httpOptions
    );
  }
  refreshToken() {
    return this.http
      .post(
        `${API_ENDPOINT}api/auth/refresh-access-token`,
        {
          accessToken: this.tokenStorage.getToken(),
        },
        httpOptions
      )

  }

  public isAuthenticated(): boolean {
    const token = this.tokenStorage.getToken();

    // Check whether the token is expired and return
    // true or false
    return !jwtHelper.isTokenExpired(token);
  }
}
