import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import { TokenStorageService } from 'src/app/shared/auth/token-storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Italiano',
      code: 'it',
      type: 'IT',
      icon: 'it'
    },

  /*
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  }
  */

  ]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  constructor(public navServices: NavService, private translate: TranslateService, private tokenStorage: TokenStorageService) { }
  //
  ngOnInit() {

    this.tokenStorage.getLang()?this.selectedLanguage = this.tokenStorage.getLang():this.selectedLanguage;
    moment.lang(this.selectedLanguage.code)
  }

  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;

    this.tokenStorage.saveLang(lang);

    // Reload necessario?
    location.reload();

  }

}
