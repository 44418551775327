import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { TokenStorageService} from '../../shared/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TemplateStampaService } from '../../components/settings/template-stampa/template-stampa.service'
import { handleError } from "src/app/shared/services/utils.services";

@Component({
  selector: "app-select-template",
  templateUrl: "./select-template.component.html",
  styleUrls: ["./select-template.component.scss"],
})
export class SelectTemplateComponent implements OnInit {
  formGroup: FormGroup;
  templates: any[];
  customers: any[];
  template: any;
  jsonData: string;
  selectedTemplateId: string;
  public errorMessage: any;
  isServiceBureau:boolean = false;
  customerId:any;
  serviceBureau:any;


  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthenticationService,
    private tokenStorage: TokenStorageService,
    private service: TemplateStampaService,
    private translate: TranslateService,
    ) {

  }

  ngOnInit() {
    this.isServiceBureau =  this.tokenStorage.getUser().roles[0]=='ROLE_SERVICE_BUREAU';
    this.translate.use(this.tokenStorage.getLang()?this.tokenStorage.getLang().code:'en');
    this.formGroup = this.formBuilder.group({
      selectedTemplateId: ['', [
          Validators.required
      ]],
      customerId: ['', [
    ]]
    });
    this.fetch();
  }

  fetch() {

    if(this.isServiceBureau){
      this.service.getListCustomers().subscribe(data => {
        this.customers = data;
        this.jsonData = JSON.stringify(data)
      },
      err => {
        handleError(err);
        this.router.navigate(['/login'])
      });
    } else {
      this.service.getList().subscribe(data => {
        this.templates = [...data]
        this.jsonData = JSON.stringify(data)
      },
      err => {
        handleError(err);
        this.router.navigate(['/login'])
      });
    }

  }

  onChange(event) {

    this.template=event;

  }

  setTemplate() {

      const { selectedTemplateId } = this.formGroup.value;
      if(this.isServiceBureau){
        this.router.navigate(['items'], {queryParams: {templateId: selectedTemplateId, customerId : this.customerId}});
      } else {
        this.router.navigate(['items'], {queryParams: {templateId: selectedTemplateId}});
      }
     

  }

  back() {
    this.router.navigate(['dashboard']);

  }

  next(){
    this.service.getListForCustomerId(this.customerId).subscribe(data => {
      this.templates = [...data]
      this.jsonData = JSON.stringify(data)
    },
    err => {
      alert(err.error.message);
      this.router.navigate(['/login'])
    });
  }

  detect(event){
    if(event!=undefined){
    this.customerId = event.id
    } else {
      this.customerId = null
    }
  }


}
