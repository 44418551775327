import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { TokenStorageService} from '../../shared/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent implements OnInit {

  id: any;

  public prForm: FormGroup;
  message: string;
  isRegistered = false;
  isRegisterFailed = false;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthenticationService,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.translate.use(this.tokenStorage.getLang()?this.tokenStorage.getLang().code:'en');

    this.prForm = this.fb.group({
      id: [this.id, [Validators.required]],
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required, this.confirmationValidator]]
    });
  }

  register() {
    console.log(this.prForm);

    const { id, password } = this.prForm.value;
    this.authService.setPassword(id, password).subscribe(
        data => {
          this.message = data.message;
          this.isRegistered = true;
        },
        err => {
            this.message = err.error.message;
            this.isRegisterFailed = true;
        }
      );
  }


  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
        return { required: true };
    } else if (control.value !== this.prForm.controls.password.value) {
        return { confirm: true, error: true };
    }
    return {};
};
}
