import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class DataTableCubaService {
    constructor(private translate: TranslateService){}
    get prev(): string{
        return this.translate.instant("PREV");
    }
    get next(): string{
        return this.translate.instant("NEXT");
    }
    doNothing(e){}
}