import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { TokenStorageService} from '../../shared/auth/token-storage.service';
import { SelectCustomerService } from "./select-customer.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-select-customer",
  templateUrl: "./select-customer.component.html",
  styleUrls: ["./select-customer.component.scss"],
})
export class SelectCustomerComponent implements OnInit {
  formGroup: FormGroup;
  customers: any[];
  selectedCustomerId = '';
  customer: any;
  public errorMessage: any;


  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthenticationService,
    private tokenStorage: TokenStorageService,
    private scService: SelectCustomerService,
    private translate: TranslateService,
    ) {

  }

  ngOnInit() {

    this.translate.use(this.tokenStorage.getLang()?this.tokenStorage.getLang().code:'en');

    this.formGroup = this.formBuilder.group({
      selectedCustomerId: ['', [
          Validators.required
      ]]
    });
    this.fetch();
  }

  fetch() {

    var user = this.tokenStorage.getUser();
    let role = '';
    if(user)
      role = user.roles[0];

    if(role==='ROLE_GROUP_ADMIN') {
      this.scService.getGroupList(user.group.id).subscribe(data => {
        this.customers = data
      });
    }
    if(role==='ROLE_ADMIN') {
      this.scService.getList().subscribe(data => {
        this.customers = data
      });
    }

  }

  onChange(event) {

    this.customer=event;

  }

  selectCustomer() {
      console.log(this.formGroup);

      const { selectedCustomerId } = this.formGroup.value;
      this.tokenStorage.saveCustomer(this.customer);

      this.router.navigate(['/dashboard']);

  }

  signout() {
    this.tokenStorage.signOut();
    window.location.reload();

  }


}
