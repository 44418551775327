import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from '../../shared/services/authentication.service';
import { TokenStorageService} from '../../shared/auth/token-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-password-recovery",
  templateUrl: "./password-recovery.component.html",
  styleUrls: ["./password-recovery.component.scss"],
})
export class PasswordRecoveryComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public prForm: FormGroup;
  public errorMessage: any;
  public isLoggedIn = false;
  public user = {};

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthenticationService,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService,
    ) {
    this.prForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.translate.use(this.tokenStorage.getLang()?this.tokenStorage.getLang().code:'en');

    if (this.authService.isAuthenticated()) {
      this.isLoggedIn = true;
      this.user = this.tokenStorage.getUser();

      console.log('already logged...')
      this.router.navigate(['/dashboard']);

    }
  }

  passwordRecovery() {

    const { email } = this.prForm.value;
    this.authService.recoverPassword(email).subscribe(
      data => {

      },
      err => {

        this.errorMessage = 'Invalid Email!'
        //alert(JSON.stringify(err))

      }
    );

  }


}
